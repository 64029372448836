import { Grid } from "@mui/material";
import React from "react";
import "react-phone-number-input/style.css";
import { useJsApiLoader } from "@react-google-maps/api";
import { useLocation, useNavigate } from "react-router-dom";
import Location from "../../components/Location";
import "../../screens/Authentication/VendorSignUp/field.css";

function VendorAddress() {
  const location = useLocation();
  const navigation = useNavigate();
  const params = location.state.data;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCj9n0oIYjF6WrdDxG7UkSUKgqJoBsBzj0",
    libraries: ["places"],
  });

  if (!isLoaded) {
    return <div>map is loading</div>;
  }

  const handleTab = (e) => {
    params.address = e;
    navigation("/VendorVirtualMarket", {
      state: { ...params },
    });
  };

  return (
    <Grid container md={12} xs={12} style={{ backgroundColor: "#F7F7F7" }}>
      <Grid
        item
        style={{
          backgroundColor: "#003D28 ",
          height: 60,
          display: "flex",
          alignItems: "center",
          position: "fixed",
          width: "100%",
        }}
        xs={12}
        md={12}
      >
        <img
          style={{ width: 220, height: 100, margin: -20 }}
          src={require("../../assets/logo1.png")}
          alt="logo"
        />
      </Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 100,
        }}
        container
        xs={12}
        md={12}
        lg={12}
      >
        <Grid md={7} xs={10} item>
          <div style={{}}>
            <div>
              <div>
                <div style={{ padding: 5 }}>
                  <div
                    style={{
                      fontSize: 40,
                      fontWeight: "700",
                    }}
                  >
                    Registro
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "700",
                    }}
                  >
                    ¡Muy bien! Ahora, registra tu dirección actual y elige la
                    opción que aparece en la lista de selección. Una vez
                    seleccionado, asegúrate de que la flecha en el mapa esté en
                    el punto correcto en su dirección.
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      fontSize: 18,
                      fontWeight: "700",
                      color: "#003D28 ",
                    }}
                  >
                    Paso 3:
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <Location
                      handleTab={(e) => {
                        handleTab(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default VendorAddress;
