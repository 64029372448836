import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React, { FC } from "react";
import { Product } from "../types";

type Props = {
  product: Product;
  onClick?: (product: Product) => void;
};

const ProductTile: FC<Props> = ({ product, children }) => {
  return (
    <Card>
      <CardMedia
        component="img"
        sx={{ height: 140 }}
        image={product.images}
        title="producto"
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          ₡{Number(product.price).toLocaleString()}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.size}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

export default ProductTile;
