import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Alert from "@mui/material/Alert";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import VendorNavbarComponent from "./VendorNavbarComponent";
import VendorDashBoard from "./VendorDashBoard";
import AuthContext from "../components/context";
import { useContext } from "react";
import VendorOrderTable from "./VendorOrderTable";
import VendorProductsTable from "./VendorProductsTable";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import axios from "../axios";
import { ToggleSlider } from "react-toggle-slider";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import {
  updatePassword,
  getAuth,
  reauthenticateWithCredential,
  updateEmail,
} from "firebase/auth";
import { useStateValue } from "../ContextApi/StateProvider";
import Colors from "../components/Colors";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import toast, { Toaster } from "react-hot-toast";

const drawerWidth = 250;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 370,
  bgcolor: "background.paper",
  borderRadius: 2,
  p: 1,
};
const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "50%",
    // height: "78%",
  },
};
function VendorOrders(props) {
  const authVendor = useContext(AuthContext);
  const [error, setError] = useState("");
  const [passwordNotMatch, setPassordNotMatch] = useState("");
  const [firebaseError, setFirebaseError] = useState("");
  const [open, setOpen] = React.useState(false);
  const [defaultInfo, setDefaultInfo] = React.useState();

  const [openModal, setModalOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    password: "",
    currentPassword: "",
    showPassword: false,
  });
  const [newValues, setNewValues] = React.useState({
    newPassword: "",
    showNewPassword: false,
  });
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
    setOpen(false);
  };

  const { windowProps } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigation = useNavigate();
  const [{ vendor }, dispatch] = useStateValue();
  const [vendorD, setVendorD] = useState();
  console.log(vendor, "vendorvendor");
  const [newName, setNewName] = React.useState("");
  console.log(newName, "newName");
  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };
  const [newPhone, setNewPhone] = React.useState("");
  console.log(newPhone, "newPhone");
  const handlePhoneChange = (e) => {
    setNewPhone(e.target.value);
  };

  const [newEmail, setNewEmail] = React.useState("");
  console.log(newEmail, "newEmail");

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };
  const [currentPassword, setCurrentPassword] = React.useState("");

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };
  const [accountNumber, setAccountNumber] = React.useState("");
  console.log(accountNumber, "accountNumber");
  const handleAccountNumber = (e) => {
    setAccountNumber(e.target.value);
  };
  const [accountName, setAccountName] = React.useState("");

  const handleAccountName = (e) => {
    setAccountName(e.target.value);
  };
  // const getVendor = async () => {

  //   let vendorData = JSON.parse(localStorage.getItem('vendor'))
  //   setVendorD(vendorData)
  // }
  const userAuth = getAuth();
  const vendorLogin = userAuth.currentUser;
  let id = authVendor?.vendor?._id;
  const [activeToggle, setActiveToggle] = useState();
  const handleUpdateUserNumber = async () => {
    await axios
      .put(`/api/v1/vendor/vendorAccountSetting/${authVendor?.vendor?._id}`, {
        cellPhone: newPhone,
      })
      .then((res) => {
        //clear storage
        //set localstorage
        console.log(res.data, "data returns");
        localStorage.removeItem("vendor");
        authVendor.setVendor("");
        authVendor.setVendor(JSON.stringify(res.data.accountSetting, null, 4));
        localStorage.setItem(
          "vendor",
          JSON.stringify(res.data.accountSetting, null, 4)
        );
        window.location.reload();
        console.log(res.data, "myNumber");
      });
  };
  const handleUpdateAccount = async () => {
    if (accountNumber.length <= 22) {
      await axios
        .put(`/api/v1/vendor/vendorAccountSetting/${authVendor?.vendor?._id}`, {
          bankDetail: {
            accountNumber: accountNumber,
            accountName: accountName,
          },
        })
        .then((res) => {
          //clear storage
          //set localstorage
          console.log(res.data, "data returns");
          localStorage.removeItem("vendor");
          authVendor.setVendor("");
          authVendor.setVendor(
            JSON.stringify(res.data.accountSetting, null, 4)
          );
          localStorage.setItem(
            "vendor",
            JSON.stringify(res.data.accountSetting, null, 4)
          );
          window.location.reload();
          console.log(res.data, "myNumber");
        });
    }
  };

  useEffect(() => {
    const getUserById = async () => {
      if (authVendor?.vendor) {
        axios
          .get(`/api/v1/vendor/${id}`)
          .then((res) => {
            console.log(res, "response");
            setActiveToggle(res.data?.vendorId?.accountStatus);
            setDefaultInfo(res.data, "datareturns");
          })
          .catch((err) => {
            console.log(err, "error");
          });
      }
    };
    getUserById();
  }, [authVendor]);
  const handleUpdateUserName = async () => {
    // console.log()
    axios
      .put(`/api/v1/vendor/vendorAccountSetting/${id}`, {
        name: newName,
      })
      .then((res) => {
        console.log(res.data, "datareturns");
        localStorage.removeItem("vendor");
        authVendor.setVendor("");
        localStorage.setItem(
          "vendor",
          JSON.stringify(res.data.accountSetting, null, 4)
        );
        authVendor.setVendor(JSON.stringify(res.data.accountSetting, null, 4));
        window.location.reload();
      });
  };
  const vendorAuth = getAuth();

  const vendorIn = vendorAuth.currentUser;
  const handleUpdateUserEmail = async () => {
    console.log(newEmail, currentPassword);

    const cred = firebase.auth.EmailAuthProvider.credential(
      vendorIn.email,
      currentPassword
    );
    reauthenticateWithCredential(vendorIn, cred)
      .then((res) => {
        updateEmail(vendorIn, newEmail)
          .then(() => {
            console.log("update");
            axios
              .put(
                `/api/v1/vendor/vendorAccountSetting/${authVendor?.vendor?._id}`,
                {
                  email: newEmail,
                }
              )
              .then((res) => {
                console.log(res.data, "datareturns");
                localStorage.removeItem("user");
                authVendor.setVendor(
                  JSON.stringify(res.data.updateName, null, 4)
                );
                localStorage.setItem(
                  "user",
                  JSON.stringify(res.data.updateName, null, 4)
                );
                setEmailModal(!EmailModal);
                window.location.reload();
              });
          })
          .catch((error) => {
            console.log(error, "error");
          });
      })
      .catch((err) => {
        console.log(err);
        setFirebaseError("");
        setPassordNotMatch(err.message);
      });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleNewChange = (prop) => (event) => {
    setNewValues({ ...newValues, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowNewPassword = () => {
    setNewValues({
      ...newValues,
      showNewPassword: !newValues.showNewPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  console.log(vendorLogin, "vendorLogin");
  const reAuthenticateVendor = () => {
    const cread = firebase.auth.EmailAuthProvider.credential(
      vendorLogin.email,
      values.currentPassword
    );
    return reauthenticateWithCredential(vendorLogin, cread);
  };
  const handleSubmitting = async () => {
    if (newValues.newPassword == values.password) {
      reAuthenticateVendor()
        .then(() => {
          updatePassword(vendorLogin, values.password)
            .then(() => {
              setPassordNotMatch("");
              setFirebaseError("");
              console.log("password upgrade");
              setOpen(true);
            })
            .catch((error) => {
              setFirebaseError("");
              setPassordNotMatch(error.message);
              console.log(error);
            });
        })
        .catch((error) => {
          setPassordNotMatch("");
          setFirebaseError(error.message);
          console.log(error);

          // setFirebaseError("current password is incorrect")
        });
    } else {
      setFirebaseError("");
      setPassordNotMatch("password does not match");
    }

    console.log(values?.currentPassword, "currentPassword");
    console.log(values?.password, "newPassword");
    console.log(newValues?.newPassword, "confirmPassword");
  };
  const [activeTab, setActiveTabs] = useState("Configuraciones de la cuenta");
  const [form, setForm] = useState([]);
  const [loading, setLoading] = useState();
  console.log(defaultInfo?.vendorId?.accountStatus);

  console.log(vendorLogin, "userin");
  const handleToggleChange = async (e) => {
    console.log(e, "toggale value");
    setActiveToggle(e);
  };
  const handleAway = async () => {
    console.log(authVendor.vendor._id);
    await axios
      .put(`/api/v1/vendor/vendorAccountSetting/${authVendor?.vendor?._id}`, {
        accountStatus: activeToggle,
      })
      .then((res) => {
        console.log(res.data, "datareturns");
        notify("Status updated successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(activeToggle, "activeToggleactiveToggle");
  const notify = (e) => toast(e);
  function handleModalClose() {
    setModalOpen(false);
  }
  function handleModalOpen() {
    setModalOpen(true);
  }
  const handleSubmit = (values) => {
    setForm({ newProduct: { ...values } });
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [nameModal, setNameModal] = React.useState(false);
  const [phoneModal, setPhoneModal] = React.useState(false);
  const [cardModal, setCardModal] = React.useState(false);

  const [EmailModal, setEmailModal] = React.useState(false);

  function closeEmailModal() {
    setEmailModal(false);
    setPassordNotMatch("");
    setFirebaseError("");
  }
  function openEmailModal() {
    setEmailModal(true);
  }
  function closeNameModal() {
    setNameModal(false);
    setPassordNotMatch("");
    setFirebaseError("");
  }
  function openNameModal() {
    setNameModal(true);
  }
  console.log(defaultInfo, "defaultInfo");
  function closePhoneModal() {
    setPhoneModal(false);
    setPassordNotMatch("");
    setFirebaseError("");
  }
  function closeCardModal() {
    setCardModal(false);
    setPassordNotMatch("");
    setFirebaseError("");
  }
  function openCardModal() {
    setCardModal(true);
  }
  function closeModal() {
    setIsOpen(false);
    setValues(null);
    setNewValues(null);
    setPassordNotMatch("");
    setFirebaseError("");
  }
  function handleModalOpen() {
    setIsOpen(true);
  }
  function openPhoneModal() {
    setPhoneModal(true);
  }
  const drawer = (
    <Grid
      md={2}
      lg={2}
      sm={2}
      sx={12}
      style={{
        width: "100%",
      }}
    >
      <div style={{ marginTop: 90 }}>
        <VendorDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
      </div>
    </Grid>
  );

  const container =
    windowProps !== undefined ? () => windowProps().document.body : undefined;

  const alterStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
  };
  return (
    <Box
      sx={{
        display: "flex",
        background: "#F6F9FC",
        width: "100%",
        height: "100vh",
        paddingTop: 10,
      }}
    >
      <Toaster />
      <VendorNavbarComponent handleDrawerToggle={handleDrawerToggle} />
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={alterStyle}>
            <Alert severity="success">Your password is upgraded </Alert>
          </Box>
        </Modal>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={alterStyle}>
          <Alert severity="success">Your password is upgraded</Alert>
        </Box>
      </Modal>
      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: 16, fontWeight: "700" }}>
              Descansa tu contraseña
            </div>
            <div>
              <CloseIcon style={{ cursor: "pointer" }} onClick={closeModal} />
            </div>
          </div>
          <FormControl sx={{ m: 1, width: "90%" }} variant="filled">
            <InputLabel htmlFor="filled-adornment-password">
              Current Password
            </InputLabel>
            <FilledInput
              id="filled-adornment-password"
              type={"password"}
              value={values?.currentPassword}
              onChange={handleChange("currentPassword")}
            />
          </FormControl>
          <div style={{ color: "red" }}>{firebaseError}</div>
          <FormControl sx={{ m: 1, width: "90%" }} variant="filled">
            <InputLabel htmlFor="filled-adornment-password">
              New password
            </InputLabel>
            <FilledInput
              id="filled-adornment-password"
              type={values?.showPassword ? "text" : "password"}
              value={values?.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values?.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "90%" }}>
            <InputLabel htmlFor="filled-adornment-password">
              Confirm password
            </InputLabel>
            <FilledInput
              id="filled-adornment-password"
              type={newValues?.showNewPassword ? "text" : "password"}
              value={newValues?.newPassword}
              onChange={handleNewChange("newPassword")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {newValues?.showNewPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <div style={{ color: "red" }}>{passwordNotMatch}</div>

          <div
            onClick={handleSubmitting}
            style={{
              cursor: "pointer",
              backgroundColor: "#2D634C",
              width: 170,
              borderRadius: 10,
              padding: 5,
              marginTop: 10,
              color: "white",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Actualiza contraseña
          </div>
        </Box>
      </Modal>
      <Modal
        open={nameModal}
        onClose={closeNameModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ fontSize: 16, fontWeight: "700" }}>
            Actualiza tu nombre
          </div>
          <TextField
            style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
            onChange={handleNameChange}
            defaultValue={defaultInfo?.vendorId?.name}
            id="outlined-search"
            label="First Name"
            type="search"
          />
          <div
            style={{
              fontWeight: "600",
              backgroundColor: "#F6F7F8",
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              width: 100,
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleUpdateUserName}
          >
            Guardar
          </div>
        </Box>
      </Modal>
      <Modal
        open={phoneModal}
        onClose={closePhoneModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ fontSize: 16, fontWeight: "700" }}>
            Actualiza tu número
          </div>
          <TextField
            style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
            onChange={handlePhoneChange}
            defaultValue={defaultInfo?.vendorId?.cellPhone}
            id="outlined-search"
            label="Number"
            type="search"
          />
          <div
            style={{
              fontWeight: "600",
              backgroundColor: "#F6F7F8",
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              width: 100,
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleUpdateUserNumber}
          >
            Guardar
          </div>
        </Box>
      </Modal>
      <Modal
        open={cardModal}
        onClose={closeCardModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ fontSize: 16, fontWeight: "700" }}>
            {defaultInfo?.vendorId?.bankDetail?.accountName
              ? "Actualiza los datos de tu tarjeta"
              : "Ingrese los detalles de su tarjeta"}
          </div>
          <TextField
            style={{ width: "100%", marginTop: 20 }}
            onChange={handleAccountName}
            defaultValue={defaultInfo?.vendorId?.bankDetail?.accountName}
            id="outlined-search"
            label="Account Name"
            type="search"
          />
          <TextField
            style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
            onChange={handleAccountNumber}
            defaultValue={defaultInfo?.vendorId?.bankDetail?.accountNumber}
            id="outlined-search"
            label="Account Number"
            type="search"
          />
          {accountNumber.length > 22 && (
            <div style={{ color: "red", fontSize: 13 }}>
              No puede tener más de 22 dígitos
            </div>
          )}
          <div
            style={{
              fontWeight: "600",
              backgroundColor: "#F6F7F8",
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              width: 100,
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleUpdateAccount}
          >
            Guardar
          </div>
        </Box>
      </Modal>
      <Modal
        open={EmailModal}
        onClose={closeEmailModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ fontSize: 16, fontWeight: "700" }}>
            Actualiza tu correo electrónico
          </div>
          <TextField
            style={{ width: "100%", marginTop: 20 }}
            onChange={handleEmailChange}
            id="outlined-search"
            label="New E-mail"
            type="search"
          />
          <TextField
            style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
            onChange={handleCurrentPassword}
            id="outlined-search"
            label="Your current password"
            type="search"
          />
          <div style={{ color: "red" }}>{passwordNotMatch}</div>
          <div
            style={{
              fontWeight: "600",
              backgroundColor: "#F6F7F8",
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              width: 100,
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleUpdateUserEmail}
          >
            Guardar
          </div>
        </Box>
      </Modal>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{
            sx: { height: "100%", background: "#F6F9FC" },
          }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              height: "90%",
              marginTop: 10,
              borderWidth: 0,
              background: "#F6F9FC",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              minHeight: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid
            style={{
              width: "100%",
              minHeight: "100vh",
              backgroundColor: "#F6F9FC",
            }}
            item
            md={12}
            lg={10}
            sm={12}
            xs={12}
          >
            <Grid md={12} xs={12}>
              <h2
                style={{
                  fontSize: "24px",
                  fontWeight: 700,
                  color: "#000",
                  padding: "10px",
                  letterSpacing: "1px",
                }}
              >
                Configuraciones de la cuenta
              </h2>
            </Grid>
            <div>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 10,
                }}
                md={12}
                xs={12}
              >
                <Grid
                  item
                  md={10}
                  xs={12}
                  style={{ paddingLeft: 30, fontWeight: "700" }}
                >
                  Información de la cuenta
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
                container
                md={12}
                xs={12}
              >
                <Grid item md={3} xs={6}>
                  <div
                    style={{
                      color: "rgb(52, 53, 56)",
                      fontWeight: "normal",
                      fontSize: 15,
                    }}
                  >
                    Dirección de correo electrónico
                  </div>
                  {defaultInfo && (
                    <div
                      style={{
                        color: " rgb(114, 118, 126)",
                        fontWeight: "normal",
                        fontSize: 15,
                      }}
                    >
                      {defaultInfo?.vendorId?.email}
                    </div>
                  )}
                </Grid>
                <Grid
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                  item
                  md={6}
                  xs={6}
                >
                  <div
                    onClick={openEmailModal}
                    style={{
                      color: "rgb(10, 173, 10)",
                      fontWeight: "600",
                      fontSize: 15,
                      cursor: "pointer",
                    }}
                  >
                    Cambiar
                  </div>
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
                container
                md={12}
                xs={12}
              >
                <Grid item md={3} xs={6}>
                  <div
                    style={{
                      color: "rgb(52, 53, 56)",
                      fontWeight: "normal",
                      fontSize: 15,
                    }}
                  >
                    Nombre de tu Tienda{" "}
                  </div>
                  {defaultInfo && (
                    <div
                      style={{
                        color: " rgb(114, 118, 126)",
                        fontWeight: "normal",
                        fontSize: 15,
                      }}
                    >
                      {defaultInfo?.vendorId?.storeName}
                    </div>
                  )}
                </Grid>
                <Grid
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                  item
                  md={6}
                  xs={6}
                ></Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
                container
                md={12}
                xs={12}
              >
                <Grid item md={3} xs={6}>
                  <div
                    style={{
                      color: "rgb(52, 53, 56)",
                      fontWeight: "normal",
                      fontSize: 15,
                    }}
                  >
                    Enlace de tu Tienda
                  </div>
                  <div>
                    <a
                      target={"_blank"}
                      href={`http://test.basimart.com/tiendas/${authVendor?.vendor?.storeName}`}
                      style={{
                        color: " rgb(114, 118, 126)",
                        fontWeight: "normal",
                        fontSize: 15,
                        display: "block",
                      }}
                    >
                      {`http://test.basimart.com/tiendas/${authVendor?.vendor?.storeName}`}
                    </a>
                  </div>
                </Grid>
                <Grid
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                  item
                  md={6}
                  xs={6}
                ></Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
                container
                md={12}
                xs={12}
              >
                <Grid
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                  item
                  md={6}
                  xs={6}
                ></Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
                container
                md={12}
                xs={12}
              >
                <Grid item md={3} xs={6}>
                  <div
                    style={{
                      color: "rgb(52, 53, 56)",
                      fontWeight: "normal",
                      fontSize: 15,
                    }}
                  >
                    Contraseña
                  </div>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                  item
                  md={6}
                  xs={6}
                >
                  <div
                    onClick={handleModalOpen}
                    style={{
                      color: "rgb(10, 173, 10)",
                      fontWeight: "600",
                      fontSize: 15,
                      cursor: "pointer",
                    }}
                  >
                    Cambiar
                  </div>
                </Grid>
              </Grid>
              {defaultInfo && (
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 10,
                  }}
                  container
                  md={12}
                  xs={12}
                >
                  <Grid item md={6} xs={6}>
                    <div
                      style={{
                        color: "rgb(52, 53, 56)",
                        fontWeight: "normal",
                        fontSize: 15,
                      }}
                    >
                      Estado de tu Tienda
                    </div>
                    <div
                      style={{
                        color: " rgb(114, 118, 126)",
                        fontWeight: "normal",
                        fontSize: 15,
                      }}
                    >
                      Enciende el interruptor para poner tu tienda inactiva.(por
                      ejemplo si estás de vacaciones o fuera de la ciudad). No
                      recibirás pedidos hasta que lo vuelvas a apagar.
                    </div>
                  </Grid>
                  <Grid
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                    item
                    md={3}
                    xs={6}
                  >
                    <Grid>
                      <ToggleSlider
                        active={activeToggle}
                        onToggle={handleToggleChange}
                      />
                      <Button
                        style={{
                          marginTop: 5,
                          padding: 5,
                          backgroundColor: Colors.seaGreen,
                          color: "white",
                          textTransform: "none",
                        }}
                        onClick={handleAway}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/* //Divider */}
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
                container
                md={12}
                xs={12}
              >
                <Grid
                  style={{ borderBottomWidth: 1, paddingBottom: 20 }}
                  item
                  md={6}
                  xs={6}
                ></Grid>
              </Grid>
              {/* //Divider */}

              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 10,
                }}
                md={12}
                xs={12}
              >
                <Grid
                  item
                  md={10}
                  xs={12}
                  style={{ paddingLeft: 30, fontWeight: "700" }}
                >
                  Información personal
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
                container
                md={12}
                xs={12}
              >
                <Grid item md={3} xs={6}>
                  <div
                    style={{
                      color: "rgb(52, 53, 56)",
                      fontWeight: "normal",
                      fontSize: 15,
                    }}
                  >
                    Tu Nombre
                  </div>
                  <div
                    style={{
                      color: " rgb(114, 118, 126)",
                      fontWeight: "normal",
                      fontSize: 15,
                    }}
                  >
                    {defaultInfo?.vendorId?.firstName}
                  </div>
                </Grid>
                <Grid
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                  item
                  md={6}
                  xs={6}
                >
                  <div
                    onClick={openNameModal}
                    style={{
                      color: "rgb(10, 173, 10)",
                      fontWeight: "600",
                      fontSize: 15,
                      cursor: "pointer",
                    }}
                  >
                    Cambiar
                  </div>
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
                container
                md={12}
                xs={12}
              >
                <Grid item md={3} xs={6}>
                  <div
                    style={{
                      color: "rgb(52, 53, 56)",
                      fontWeight: "normal",
                      fontSize: 15,
                    }}
                  >
                    Tu Número de teléfono
                  </div>
                  <div
                    style={{
                      color: " rgb(114, 118, 126)",
                      fontWeight: "normal",
                      fontSize: 15,
                    }}
                  >
                    {defaultInfo?.vendorId?.cellPhone}
                  </div>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",

                    paddingBottom: 20,
                  }}
                  item
                  md={6}
                  xs={6}
                >
                  <div
                    onClick={openPhoneModal}
                    style={{
                      color: "rgb(10, 173, 10)",
                      fontWeight: "600",
                      fontSize: 15,
                      cursor: "pointer",
                    }}
                  >
                    Cambiar
                  </div>
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
                container
                md={12}
                xs={12}
              >
                <Grid style={{ paddingBottom: 20 }} item md={3} xs={6}>
                  <div
                    style={{
                      color: "rgb(52, 53, 56)",
                      fontWeight: "normal",
                      fontSize: 15,
                    }}
                  >
                    Detalles de tu Banco
                  </div>
                  <div
                    style={{
                      color: " rgb(114, 118, 126)",
                      fontWeight: "normal",
                      fontSize: 15,
                    }}
                  >
                    {defaultInfo?.vendorId?.bankDetail?.accountNumber}
                  </div>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                  item
                  md={6}
                  xs={6}
                >
                  <div
                    onClick={openCardModal}
                    style={{
                      color: "rgb(10, 173, 10)",
                      fontWeight: "600",
                      fontSize: 15,
                      cursor: "pointer",
                    }}
                  >
                    Cambiar
                  </div>
                </Grid>
                {/* //Divider */}
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 10,
                  }}
                  container
                  md={12}
                  xs={12}
                >
                  <Grid
                    style={{ borderBottomWidth: 1, paddingBottom: 20 }}
                    item
                    md={6}
                    xs={6}
                  ></Grid>
                </Grid>
                {/* //Divider */}
              </Grid>
            </div>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

VendorOrders.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windowProps: PropTypes.func,
};

export default VendorOrders;

const balanceStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  todayOrders: {
    backgroundColor: Colors.seaGray,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  MonthlyOrders: {
    backgroundColor: Colors.seaBlue,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  totalOrders: {
    backgroundColor: Colors.lightGreen,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  iconStyle: {
    color: "white",
    fontSize: 18,
    fontWeight: "600",
  },
  textStyle: { color: "white", fontSize: 17, fontWeight: "600" },
  balanceStyle: { color: "#4B566B", fontSize: 25, fontWeight: "700" },
};
const orderStyles = {
  orderMainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  orderChildContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    display: "flex",

    alignItems: "center",
    marginBottom: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
  iconStyleTotal: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#F7DABD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStylePending: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#C3DDFD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleProcessing: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#AFECEF",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleDelivered: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#BCF0DA",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  orderTextStyle: {
    color: "black",
    fontSize: 14,
    fontWeight: "600",
  },
  orderQuantity: { color: "#4B566B", fontSize: 17, fontWeight: "700" },
};
