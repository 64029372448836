import { compose, withProps } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import { useEffect, useState } from "react";
import markerIcon from "../assets/marker.png";
import Autocomplete from "react-google-autocomplete";
import { Grid } from "@mui/material";
// import { useSelector } from "react-redux";

const center = {
  lat: 10.01478829139795,
  lng: -84.21344394815075,
};
const MY_API_KEY = "AIzaSyCj9n0oIYjF6WrdDxG7UkSUKgqJoBsBzj0";
const CustomSubmitMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${MY_API_KEY}&libraries=geometry,drawing,places&v=weekly&channel=2`,

    loadingElement: <div style={{ height: "60vh", width: "100%" }} />,
    containerElement: <div style={{ height: "60vh", width: "100%" }} />,
    mapElement: (
      <div id={"submitListingMap"} style={{ height: "60vh", width: "100%" }} />
    ),
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    google={props.google}
    defaultZoom={12}
    defaultCenter={props.markerPosition}
  >
    {/*Marker*/}
    <Marker
      icon={markerIcon}
      draggable={true}
      onDragEnd={props.onMarkerDragEnd}
      position={props.markerPosition}
    />
    {/* <Marker /> */}
    {/* For Auto complete Search Box */}
  </GoogleMap>
));
const SubmitListingMap = (props) => {
  // const country = 'es'
  const [markerData, setMarkerData] = useState([]);
  const [mapData, setMapData] = useState([]);

  useEffect(() => {
    if (props.markerPosition) {
      setMarkerData(props.markerPosition);
    }
    if (props.mapPosition) {
      setMapData(props.mapPosition);
    }
    // eslint-disable-next-line
  }, [props]);
  return (
    <Grid style={{ marginLeft: 5 }} container md={12} xs={12}>
      <Grid item md={12} xs={12}>
        <Autocomplete
          placeholder="Introduce una ubicación"
          style={{
            width: "100%",
            height: "50px",
            marginBottom: 10,
            padding: 10,
            borderRadius: 10,
          }}
          apiKey={MY_API_KEY}
          options={{
            types: ["(regions)"],
            componentRestrictions: { country: "CR" },
            administrativeArea: "San Jose",
          }}
          onChange={(e) => {
            e.preventDefault();
          }}
          onPlaceSelected={props.onPlaceSelected}
          types={["(regions)"]}
        />
      </Grid>
      <Grid style={{ marginBottom: 20 }} item md={12} xs={12}>
        <CustomSubmitMap
          mapPosition={mapData}
          markerPosition={markerData}
          onPlaceSelected={props.onPlaceSelected}
          onMarkerDragEnd={props.onMarkerDragEnd}
          country="us"
        />
      </Grid>
    </Grid>
  );
};

export default SubmitListingMap;
