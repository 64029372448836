import PropTypes from "prop-types";

import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import AuthContext from "../components/context";
import { useContext } from "react";
import VendorOrderTable from "./VendorOrderTable";
import axios from "../axios";
import Layout from "../components/Layout";

import io from "socket.io-client";
import PageTitle from "../components/PageTitle";

const status = [
  {
    id: 1,
    type: "Confirmando Pedido",
  },
  {
    id: 2,
    type: "Pedido Confirmado",
  },
  {
    id: 3,
    type: "Procesando Pedido",
  },
  {
    id: 4,
    type: "Pedido Entregado",
  },
  {
    id: 5,
    type: "Cancelada",
  },
];

function VendorOrders(props) {
  const [order, setOrder] = useState([]);
  const [orderStatus, setOrderStatus] = useState();
  const [vendor, setVendor] = useState();

  const [loading, setLoading] = useState();
  const auth = useContext(AuthContext);

  const handleStatus = async (e) => {
    setOrderStatus(e.target.value);
    setLoading(true);
    const req = await axios.post(`/api/v1/vendor/order/${auth.vendor._id}`, {
      type: e.target.value,
    });
    setOrder(req?.data?.order);
    setLoading(false);
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const req = await axios.post(`/api/v1/vendor/order/${auth.vendor._id}`);
      setOrder(req?.data?.order);
      setLoading(false);
      axios.get(`/api/v1/vendor/${auth.vendor._id}`).then((res) => {
        setVendor(res.data.vendorId);
      });
    }

    const socket = io(
      "https://urchin-app-49cxg.ondigitalocean.app/api/socket",
      {
        transports: ["websocket"],
      }
    ).connect();
    socket.on("newThought", (thought) => {
      console.log(thought, "hello");
      return fetchData();
    });
    fetchData();
  }, [auth]);

  function handleStatusName(status) {
    if (status === "Confirmando Pedido") return "Confirmando";
    if (status === "Pedido Confirmado") return "Confirmado";
    if (status === "Procesando Pedido") return "Procesando";
    if (status === "Pedido Entregado") return "Entregado";
    if (status === "Cancelada") return "Cancelado";
    return status;
  }

  return (
    <Layout tabName="Ordenes" loading={loading}>
      <Grid item md={12} lg={10} sm={12} xs={12}>
        <Grid>
          <Grid md={12} xs={12}>
            <PageTitle>Ordenes</PageTitle>
          </Grid>
          <Grid style={{ width: "80%", padding: 20 }} md={6} xs={8}>
            <InputLabel id="aisle" style={{}}>
              <p style={{ fontSize: "12px" }}>Estado del pedido</p>
            </InputLabel>
            <Select
              displayEmpty={false}
              id="aisle"
              value={orderStatus}
              onChange={handleStatus}
              label="Estado del pedido"
              style={{ width: "80%" }}
            >
              {status.map((item) => (
                <MenuItem value={item.type} style={{ fontSize: "13px" }}>
                  {handleStatusName(item.type)}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item md={12} lg={10} sm={12} xs={12}>
            {!!order && <VendorOrderTable order={order} vendor={vendor} />}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

VendorOrders.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default VendorOrders;
