import React from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { isMobile } from "react-device-detect";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

export default function VerifyOrderPinModal({
  open,
  onClose,
  onVerify,
}: {
  open: boolean;
  onClose: () => void;
  onVerify: (pin: string) => Promise<void>;
}) {
  const validationSchema = Yup.object().shape({
    pin: Yup.string()
      .matches(
        /^[0-9]{4}$/,
        "El PIN debe contener solo dígitos y tener exactamente 4 caracteres."
      )
      .required("Se requiere el PIN"),
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={{
        modal: {
          width: isMobile ? undefined : 500,
        },
      }}
    >
      <h4 className="text-lg m-0 mb-3">Ingresa el PIN</h4>
      <Formik
        initialValues={{
          pin: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => onVerify(values.pin)}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <Field
              name="pin"
              placeholder="PIN"
              component={TextField}
              size="small"
              className="!w-full"
            ></Field>

            <div className="flex justify-center mt-4">
              <button
                disabled={isSubmitting}
                type="submit"
                className="!bg-[#013d29] !text-white px-4 py-2 border-none cursor-pointer rounded hover:!bg-[#013d29d6]"
              >
                {"Continuar"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
