import Geocode from "react-geocode";
import { Formik, Form } from "formik";
import { makeStyles } from "@mui/styles";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SubmitListingMap from "./SubmitListingMap";
import { Grid } from "@mui/material";
import "./Admin.css";
Geocode.setApiKey("AIzaSyCj9n0oIYjF6WrdDxG7UkSUKgqJoBsBzj0");
Geocode.enableDebug();

const useStyles = makeStyles(() => ({
  btn: {
    cursor: "pointer",
    backgroundColor: "#003D28 ",
    width: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    padding: 10,
    color: "white",
    borderWidth: 0,
    marginTop: 20,
    marginLeft: 10,
  },
}));

let map;

export default function Location({ handleTab }) {
  const center = {
    lat: 10.01478829139795,
    lng: -84.21344394815075,
  };
  const classes = useStyles();
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [block, setBlock] = useState("");

  const [mapPosition, setMapPosition] = useState(center);
  const [markerPosition, setMarkerPosition] = useState(center);
  const [loadingMap, setLoadingMap] = useState(false);
  const [blockPlaceholder, setBlockPlaceholder] = useState("Block");

  const [data, setData] = useState();
  const [lang, setLang] = useState("en");
  const customLabelStyle = {
    display: "flex",
    flexDirection: lang.langIndex === 2 ? "row-reverse" : "row",
  };

  useEffect(() => {
    if (data?.city) {
      setCountry(data?.country);
      setCity(data?.city);
      setArea(data?.area);
      setBlock(data?.block);
      setAddress(data?.loca);
      setMapPosition({
        lat: data?.lat,
        lng: data?.lng,
      });
      setMarkerPosition({
        lat: data?.lat,
        lng: data?.lng,
      });
    }
    // eslint-disable-next-line
  }, [data]);
  const getCountry = (addressArray) => {
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "country" === addressArray[i].types[0]) {
        return addressArray[i].long_name;
      }
    }
    return "Pakistan";
  };
  const getCity = (addressArray) => {
    let city = "";

    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  const getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  const getBlock = (addressArray) => {
    let block = null;
    addressArray.forEach((address) => {
      address?.types?.forEach((type) => {
        if (type === "sublocality_level_2") {
          block = address.short_name;
        }
      });
    });
    return block;
  };

  const onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = getCity(addressArray),
          area = getArea(addressArray);

        const _block = getBlock(addressArray);
        if (!_block) setBlockPlaceholder("Please provide a block");

        setBlock(_block || "");
        setCountry(getCountry(addressArray));
        setAddress(address);
        setCity(city);
        setArea(area);
        setMarkerPosition({
          lat: newLat,
          lng: newLng,
        });
        setMapPosition({
          lat: newLat,
          lng: newLng,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  /* When the user types an address in the search box*/
  const onPlaceSelected = (place) => {
    let addressArray = place.address_components;

    const _country = getCountry(addressArray);
    const _city = getCity(addressArray);
    const _area = getArea(addressArray);
    const _block = getBlock(addressArray);
    const latValue = place.geometry.location.lat();
    const lngValue = place.geometry.location.lng();

    // customLog({ addressArray, place });
    // customLog({ _country, _city, _area, _block, latValue, lngValue });

    // if (!_block) setBlockPlaceholder("Please provide a block");

    setLoadingMap(true);
    setAddress(place.formatted_address);
    setCity(_city ? _city : "");
    setArea(_area);
    setCountry(_country);
    setBlock(_block || "");
    setMarkerPosition({
      lat: latValue,
      lng: lngValue,
    });
    setMapPosition({
      lat: latValue,
      lng: lngValue,
    });
    setLoadingMap(false);
  };

  const initialValues = {
    country: country ? country : "",
    city: city ? city : "",
    area: area ? area : "",
    loca: address ? address : "",
    block: block ? block : "",
    lat: mapPosition.lat ? mapPosition.lat : "",
    lng: mapPosition.lng ? mapPosition.lng : "",
  };

  if (center.lat !== undefined) {
    map = (
      <Container style={{ backgroundColor: "#F7F7F7" }}>
        <Grid
          container
          md={12}
          xs={12}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Grid md={3} xs={12} style={{}}>
            <Formik
              initialValues={initialValues}
              onSubmit={() => {
                let formData = new FormData();
                formData.append("country", country);
                formData.append("city", city);
                formData.append("area", area);
                formData.append("loca", address);
                formData.append("block", block);
                formData.append("lat", mapPosition.lat);
                formData.append("lng", mapPosition.lng);

                setData({ ...data, ...initialValues });
                console.log(data, "data");

                handleTab(initialValues);
                // setNextTab(3);
              }}
            >
              {({ handleChange, handleBlur }) => (
                <Form>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="form-group">
                      <label htmlFor="" style={customLabelStyle}>
                        {/* {TextTranslation.country[lang.langIndex]} */}
                      </label>
                      <input
                        type="text"
                        name="country"
                        className="form-control"
                        readOnly="readOnly"
                        value={country}
                        onChange={handleChange}
                        onInput={handleChange}
                        style={{ borderRadius: 5, padding: 3 }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="" style={customLabelStyle}>
                        {" "}
                        {/* {TextTranslation.city[lang.langIndex]} */}
                      </label>
                      <input
                        type="text"
                        name="city"
                        className="form-control"
                        readOnly="readOnly"
                        value={city}
                        onChange={handleChange}
                        onInput={handleChange}
                        style={{ borderRadius: 5, padding: 3 }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="" style={customLabelStyle}>
                        {/* {TextTranslation.area[lang.langIndex]} */}
                      </label>
                      <input
                        type="text"
                        name="area"
                        className="form-control"
                        readOnly="readOnly"
                        value={area}
                        onChange={handleChange}
                        onInput={handleChange}
                        style={{ borderRadius: 5, padding: 3 }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="" style={customLabelStyle}>
                        {" "}
                        {/* {TextTranslation.address[lang.langIndex]} */}
                      </label>
                      <input
                        type="text"
                        name="loca"
                        className="form-control"
                        readOnly="readOnly"
                        value={address}
                        onChange={handleChange}
                        onInput={handleChange}
                        style={{ borderRadius: 5, padding: 3 }}
                      />
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="" style={customLabelStyle}>
                      </label>
                      <input
                        type="text"
                        name="block"
                        className="form-control"
                        value={block}
                        placeholder={blockPlaceholder}
                        onChange={(e) => {
                          e.preventDefault();
                          setBlock(e.target.value);
                          handleChange(e);
                        }}
                        style={{ borderRadius: 5, padding: 3 }}
                        onInput={handleChange}
                      />
                    </div> */}

                    <div className="form-group">
                      <label htmlFor="" style={customLabelStyle}>
                        {/* {TextTranslation.latitude[lang.langIndex]} */}
                      </label>
                      <input
                        type="text"
                        name="lat"
                        className="form-control"
                        readOnly="readOnly"
                        value={mapPosition.lat}
                        onChange={handleChange}
                        onInput={handleChange}
                        style={{ borderRadius: 5, padding: 3 }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="" style={customLabelStyle}>
                        {/* {TextTranslation.longitude[lang.langIndex]} */}
                      </label>
                      <input
                        type="text"
                        name="lng"
                        className="form-control"
                        readOnly="readOnly"
                        value={mapPosition.lng}
                        onChange={handleChange}
                        onInput={handleChange}
                        style={{ borderRadius: 5, padding: 3 }}
                      />
                    </div>
                    <Row className="justify-content-around">
                      <button
                        style={{ marginBottom: 10 }}
                        className={classes.btn}
                        type="submit"
                      >
                        Confirmar
                      </button>
                    </Row>
                  </div>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid style={{ backgroundColor: "#F7F7F7" }} md={9} xs={12}>
            {loadingMap ? (
              <h1>Loading</h1>
            ) : (
              <SubmitListingMap
                markerPosition={markerPosition}
                onPlaceSelected={onPlaceSelected}
                onMarkerDragEnd={onMarkerDragEnd}
                mapPosition={mapPosition}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    map = <div style={{ height: "100vh" }} />;
  }
  return map;
}
