import { Badge, Box, Grid, LinearProgress, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import AuthContext from "../components/context";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import axios from "../axios";
import { TeamPurchase } from "../types";
import ProductTile from "../components/ProductTile";
import { DealCountdown } from "../components/DealCountdown";
import ShareProduct from "../components/ShareProduct";

const BasiTeams: FC = () => {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState<TeamPurchase[]>([]);

  useEffect(() => {
    async function getProducts() {
      setLoading(true);
      axios
        .get(`/api/v1/vendor/team-purchases/${auth?.vendor?._id}`)
        .then((res) => {
          setTeams(res?.data?.teamPurchases || []);
        })
        .finally(() => setLoading(false));
    }
    getProducts();
  }, [auth]);

  return (
    <Layout tabName="Basi Teams" loading={loading}>
      <Grid container sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Grid item xs={12} sx={{ marginBottom: 6 }}>
          <PageTitle>Basi Teams</PageTitle>
        </Grid>
        {teams.map(
          ({
            product,
            teamSize,
            currentTeam,
            teams,
            teamsQuantity,
            percentage,
            expirationDate,
          }) => (
            <Grid item xs={6} md={3} lg={2} key={product._id}>
              <Badge badgeContent={`-${percentage}%`} color="error">
                <ProductTile product={product}>
                  <div style={{ textAlign: "right" }}>
                    <ShareProduct
                      storeName={auth?.vendor?.storeName || ""}
                      productId={product._id}
                      size={16}
                    />
                  </div>
                  <DealCountdown expirationDate={expirationDate} />
                  <Typography variant="caption">
                    Compradores (
                    {
                      teams[
                        currentTeam < teamsQuantity
                          ? currentTeam
                          : currentTeam - 1
                      ].length
                    }{" "}
                    de {teamSize})
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={
                      (teams[
                        currentTeam < teamsQuantity
                          ? currentTeam
                          : currentTeam - 1
                      ].length /
                        teamSize) *
                      100
                    }
                  />
                  <Typography variant="caption">
                    Equipos ({currentTeam} de {teamsQuantity})
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={(currentTeam / teamsQuantity) * 100}
                  />
                </ProductTile>
              </Badge>
            </Grid>
          )
        )}
      </Grid>
    </Layout>
  );
};

export default BasiTeams;
