import "./App.css";

import AllRoute from "./components/AllRoute";
import { useState } from "react";
import { useEffect } from "react";
import AuthContext from "./components/context";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";

function App() {
  const [vendor, setVendor] = useState();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentVendor) => {
      setLoading(true);
      setUser(currentVendor);
      setLoading(false);
    });
    return unsubscribe;
  }, [auth]);

  function getUser() {
    const u = localStorage.getItem("vendor");
    if (u === null || u === "" || u === "undefined") {
      return;
    }
    const us = JSON.parse(u);
    setVendor(us);
  }
  useEffect(() => {
    getUser();
  }, []);

  return (
    <AuthContext.Provider value={{ vendor, setVendor }}>
      <div style={{ backgroundColor: "#F6F9FC" }}>
        {loading ? <div>loading ...</div> : <AllRoute user={user} />}
      </div>
    </AuthContext.Provider>
  );
}

export default App;
