import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import VendorProductsTable from "./VendorProductsTable";
import axios from "../axios";
import AuthContext from "../components/context";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";

function VendorProducts() {
  const [loading, setLoading] = useState();
  const [products, setProducts] = useState();

  const auth = useContext(AuthContext);

  useEffect(() => {
    async function getProducts() {
      setLoading(true);
      axios
        .get(`/api/v1/vendor/${auth?.vendor?._id}`)
        .then((res) => {
          console.log(res.data.vendorId.products, "getProductVendor");

          setProducts(res?.data?.vendorId?.products);
        })
        .finally(() => setLoading(false));
    }
    getProducts();
  }, [auth]);

  return (
    <Layout tabName="Productos" loading={loading}>
      <Grid item md={12} lg={10} sm={12} xs={12}>
        <Grid>
          <Grid md={12} xs={12}>
            <PageTitle>Productos</PageTitle>
          </Grid>
          <Grid
            style={{ display: "flex", justifyContent: "flex-end" }}
            md={12}
            xs={12}
          ></Grid>
          <Grid item md={12} lg={10} sm={12} xs={12}>
            {products?.length > 0 && products ? (
              <VendorProductsTable data2={products} />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "Center",
                  fontSize: 25,
                  fontWeight: "600",
                }}
              >
                Sin productos
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

VendorProducts.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default VendorProducts;
