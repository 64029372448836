import * as React from "react";

import TablePagination from "@mui/material/TablePagination";

import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { isMobile } from "react-device-detect";
import { Grid } from "@mui/material";
import { Button } from "react-bootstrap";
import { commafy } from "../components/commafy";
import { formatDate } from "../utils/formatDate";

export default function VendorOrderTable({ order, vendor }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleStatus(status) {
    if (status === "Confirmando Pedido") return "Confirmando";
    if (status === "Pedido Confirmado") return "Confirmado";
    if (status === "Procesando Pedido") return "Procesando";
    if (status === "Pedido Entregado") return "Entregado";
    if (status === "Cancelada") return "Cancelado";
    return status;
  }

  const styles = {
    tableHeading: { background: "#529f6f", height: "1.5px", width: "100%" },

    mdTable: {
      background: "white",
      borderSpacing: "60px 10px",
      borderCollapse: "separate",
      paddingLeft: "20px",
      marginLeft: "20px",
    },
    mdTableMobile: {
      background: "white",
      borderSpacing: "20px 8px",
      borderCollapse: "separate",
      marginTop: "-65px",
    },

    tableBody: {
      color: "black",
      fontSize: 12,
      textAlign: "center",
      margin: "auto",
    },
    tableTxt: {
      fontSize: 13,
      color: "black",
      padding: "2px",
      margin: "auto",
      textAlign: "center",
      fontWeight: "200",
    },
    valueTxt: {
      fontSize: 12,
      color: "#222",
    },
    hightLightTxt: {
      fontSize: 12,
      color: "#AD6D49",
      margin: 0,
    },

    tittle: {
      color: "#4B566B",
      fontSize: 15,
      fontWeight: "bold",
      paddingLeft: "10px",
      marginTop: "20px",
    },

    tittleMobile: {
      color: "#4B566B",
      fontSize: 15,
      fontWeight: "bold",
      paddingLeft: "3px",
      marginTop: "10px",
    },

    subTittle: {
      color: "#4B566B",
      fontSize: 13,
      fontWeight: "bold",
      paddingLeft: "10px",
    },

    confirmando: {
      width: 76,
      height: 17,
      borderRadius: 5,
      backgroundColor: "#eae7f0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 11,
      padding: 15,
      textAlign: "center",
      fontWeight: "bold",
      color: "#423363",
    },
    container: {
      background: "#f2f2f4",
      width: "100%",
      borderRadius: 8,
      borderWidth: 2,
      borderColor: "white",
      padding: "10px",
      margin: "5px",
      height: "160px",
    },
  };

  const navigate = useNavigate();

  const navigateToDetail = (row) => {
    navigate("/OrderDetail", {
      state: {
        ...row,
      },
    });
  };

  return (
    <>
      <div style={{ width: "98%", background: "#F6F9FC", overflow: "scroll" }}>
        {!isMobile && (
          <MDBTable responsive style={styles.mdTable}>
            <MDBTableHead>
              <tr>
                <th scope="col" style={styles.subTittle}>
                  {"Ordenes"}
                </th>
                <th scope="col" style={styles.subTittle}>
                  {"Estado"}
                </th>
                <th scope="col" style={styles.subTittle}>
                  {"Fecha de compra"}{" "}
                </th>
                <th scope="col" style={styles.subTittle}>
                  {"Total"}{" "}
                </th>
                <th scope="col" style={styles.subTittle}>
                  {`Comisión`}
                </th>
              </tr>
            </MDBTableHead>
            {order
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => {
                return (
                  <MDBTableBody>
                    <tr
                      style={{
                        marginTop: "10px",
                        marginButton: "10px",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigateToDetail(row);
                      }}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <th scope="row" style={styles.tableTxt}>
                        {" "}
                        {row._id}{" "}
                      </th>
                      {row.status === "Confirmando Pedido" && (
                        <th>
                          <div
                            style={{
                              width: 90,
                              height: 28,
                              borderRadius: 20,
                              backgroundColor: "#CED7DF",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                              padding: 20,
                              textAlign: "center",
                            }}
                          >
                            {handleStatus(row?.status)}
                          </div>
                        </th>
                      )}
                      {row.status === "Pedido Confirmado" && (
                        <th>
                          <div
                            style={{
                              width: 90,
                              height: 28,
                              borderRadius: 20,
                              backgroundColor: "#dae2e8",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                              padding: 20,
                              textAlign: "center",
                            }}
                          >
                            {handleStatus(row?.status)}
                          </div>
                        </th>
                      )}
                      {row.status === "Procesando Pedido" && (
                        <th>
                          <div
                            style={{
                              width: 90,
                              height: 28,
                              borderRadius: 20,
                              backgroundColor: "#e6e8ea ",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                              color: "black",
                              padding: 20,
                              textAlign: "center",
                            }}
                          >
                            {handleStatus(row?.status)}
                          </div>
                        </th>
                      )}
                      {row.status === "Pedido Entregado" && (
                        <th>
                          <div
                            style={{
                              width: 90,
                              height: 28,
                              borderRadius: 20,
                              backgroundColor: "#E0F7E9",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                              color: "#65D068",
                              padding: 20,
                              textAlign: "center",
                            }}
                          >
                            {handleStatus(row?.status)}
                          </div>
                        </th>
                      )}
                      {row.status === "Cancelada" && (
                        <th>
                          <div
                            style={{
                              width: 90,
                              height: 28,
                              borderRadius: 20,
                              backgroundColor: "#F9E1E6",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                              color: "#E74460",
                            }}
                          >
                            {handleStatus(row?.status)}
                          </div>
                        </th>
                      )}

                      <th style={styles.tableTxt}>
                        {formatDate(row.purchaseDate)}
                      </th>
                      <th style={styles.tableTxt}>
                        ₡{commafy(row.totalPrice)}
                      </th>
                      {row.status === "Pedido Entregado" && vendor ? (
                        <th style={styles.tableTxt}>
                          ₡{row.vendorCommission || 0}
                        </th>
                      ) : (
                        <th style={styles.tableTxt}>₡ 0</th>
                      )}
                    </tr>
                  </MDBTableBody>
                );
              })}
          </MDBTable>
        )}

        {isMobile && (
          <>
            {order
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => {
                return (
                  <div style={styles.container}>
                    <Grid container xs={12}>
                      <Grid xs={8}>
                        Orden Id #
                        <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                          {row._id}{" "}
                        </p>
                      </Grid>

                      <Grid xs={4}>
                        <>
                          {row.status === "Confirmando Pedido" && (
                            <div
                              style={{
                                width: 90,
                                height: 24,
                                borderRadius: 5,
                                backgroundColor: "#CED7DF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: 11,
                                padding: 13,
                                textAlign: "center",
                              }}
                            >
                              {handleStatus(row?.status)}
                            </div>
                          )}

                          {row.status === "Pedido Confirmado" && (
                            <div
                              style={{
                                width: 90,
                                height: 24,
                                borderRadius: 5,
                                backgroundColor: "#dae2e8",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: 11,
                                padding: 13,
                                textAlign: "center",
                              }}
                            >
                              {handleStatus(row?.status)}
                            </div>
                          )}

                          {row.status === "Procesando Pedido" && (
                            <th>
                              <div
                                style={{
                                  width: 90,
                                  height: 24,
                                  borderRadius: 5,
                                  backgroundColor: "#e6e8ea",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: 11,
                                  color: "black",
                                  padding: 13,
                                  textAlign: "center",
                                }}
                              >
                                {handleStatus(row?.status)}
                              </div>
                            </th>
                          )}

                          {row.status === "Pedido Entregado" && (
                            <th>
                              <div
                                style={{
                                  width: 90,
                                  height: 24,
                                  borderRadius: 5,
                                  backgroundColor: "#E0F7E9",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: 11,
                                  color: "#65D068",
                                  padding: 13,
                                  textAlign: "center",
                                }}
                              >
                                {handleStatus(row?.status)}
                              </div>
                            </th>
                          )}

                          {row.status === "Cancelada" && (
                            <th>
                              <div
                                style={{
                                  width: 90,
                                  height: 24,
                                  borderRadius: 5,
                                  backgroundColor: "#F9E1E6",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: 11,
                                  color: "#E74460",
                                }}
                              >
                                {handleStatus(row?.status)}
                              </div>
                            </th>
                          )}
                        </>
                      </Grid>
                    </Grid>

                    <Grid container xs={12}>
                      <p style={{ fontSize: 11, paddingTop: "16px" }}>
                        {" "}
                        Fecha de Compra:
                      </p>
                      <p
                        style={{
                          fontSize: 11,
                          paddingTop: "16px",
                          fontWeight: "bold",
                          paddingLeft: "6px",
                        }}
                      >
                        {row.purchaseDate}
                      </p>
                    </Grid>

                    <Grid container xs={12}>
                      <Grid
                        xs={6}
                        style={{ display: "flex", alignItems: "stretch" }}
                      >
                        <p style={{ fontSize: 11, paddingTop: "5px" }}>
                          {" "}
                          Comision:
                        </p>
                        {row.status === "Pedido Entregado" && vendor ? (
                          <p
                            style={{
                              fontSize: 11,
                              paddingTop: "5px",
                              fontWeight: "bold",
                              paddingLeft: "6px",
                            }}
                          >
                            ₡
                            {vendor?.commission && (
                              <>
                                {(Number(row?.totalPrice) +
                                  Number(row?.pointsApplied)) *
                                  (Number(vendor?.commission) / 100).toFixed(2)}
                              </>
                            )}
                            {!vendor.commission && <>0</>}
                          </p>
                        ) : (
                          <p
                            style={{
                              fontSize: 11,
                              paddingTop: "5px",
                              fontWeight: "bold",
                              paddingLeft: "6px",
                            }}
                          >
                            ₡ 0
                          </p>
                        )}
                      </Grid>

                      <Grid
                        xs={6}
                        style={{
                          display: "flex",
                          alignItems: "stretch",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 11,
                            paddingTop: "5px",
                            paddingLeft: "20px",
                          }}
                        >
                          {" "}
                          Total:
                        </p>
                        <p
                          style={{
                            fontSize: 11,
                            paddingTop: "5px",
                            fontWeight: "bold",
                            paddingLeft: "6px",
                          }}
                        >
                          ₡{commafy(row.totalPrice)}
                        </p>
                      </Grid>
                    </Grid>

                    <Grid xs={4}>
                      <Button
                        style={{
                          fontSize: "10px",
                          fontWeight: "bold",
                          background: "#003D28",
                          color: "white",
                          borderRadius: "5px",
                          padding: "5px",
                          marginTop: "10px",
                        }}
                        onClick={() => {
                          navigateToDetail(row);
                        }}
                      >
                        {" "}
                        Ver Detalles{" "}
                      </Button>
                    </Grid>
                  </div>
                );
              })}
          </>
        )}
        <div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={order.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>

      <div style={{ heigth: "50px" }}></div>
    </>
  );
}
