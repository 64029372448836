import { Grid, Input } from "@mui/material";
import React, { useRef, useState, useContext } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TextField } from "formik-material-ui";
// import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BoltIcon from "@mui/icons-material/Bolt";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import "react-phone-number-input/style.css";
import { Checkbox } from "@mui/material";
import { CreditCard } from "@material-ui/icons";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./field.css";
import { useJsApiLoader, GoogleMap, MarkerF } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import Colors from "../../../components/Colors";
import axios from "../../../axios";
import AuthContext from "../../../components/context";

const addressScheme = Yup.object().shape({
  phone: Yup.number().required("Su número de teléfono es requerido"),
  email: Yup.string().required("Correo Electrónico es requerido"),

  password: Yup.string()
    .required("Contraseña es requerido")
    .min(6, "Al menos 6 caracteres"),
});
function VendorSignUp() {
  const location = useLocation();
  const params = location?.state?.defaultData;
  console.log(params, "dataroute");
  const navigation = useNavigate();
  const [form, setForm] = useState();
  const [error, setError] = useState("");
  const authContext = useContext(AuthContext);

  console.log(error, "errorerrorerror");
  console.log(form?.address, "myform");
  const handleSubmit = async (values) => {
    setForm({ address: { ...values } });
    axios
      .post("/api/v1/vendor/vendorChecking", {
        email: values.email,
      })
      .then((res) => {
        console.log(res.vendor);
        authContext.setVendor(JSON.stringify(res.data.vendor, null, 4));
        localStorage.setItem(
          "vendor",
          JSON.stringify(res.data.vendor, null, 4)
        );
        navigation("/VendorRecord", {
          state: {
            data: { ...values },
            params: { ...params },
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setError(err?.response?.data?.message);
      });
  };
  return (
    <Grid
      container
      md={12}
      xs={12}
      style={{ backgroundColor: "#F7F7F7", minHeight: "100vh" }}
    >
      <Grid
        item
        style={{
          backgroundColor: "#003D28 ",
          height: 60,
          display: "flex",
          alignItems: "center",
          position: "fixed",
          width: "100%",
        }}
        xs={12}
        md={12}
      >
        <img
          style={{ width: 220, height: 100, margin: -20 }}
          src={require("../../../assets/logo1.png")}
        />
      </Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 100,
        }}
        container
        xs={12}
        md={12}
        lg={12}
      >
        <Grid md={7} xs={10} item>
          <div
            style={{
              fontSize: 16,
              fontWeight: "700",
              fontSize: 23,
              color: "#003D28 ",
            }}
          >
            Regístrate{" "}
          </div>
          <div style={{}}>
            <Formik
              initialValues={{
                phone: params ? params?.phone : "",
                email: params ? params?.email : "",
                password: params ? params?.password : "",
              }}
              validationSchema={addressScheme}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({
                values,

                handleChange,
                handleBlur,
                isSubmitting,
              }) => (
                <div>
                  <Form encType="multipart/form-data">
                    <div>
                      {/* Vendor Email and Number */}
                      <div style={{ padding: 5 }}>
                        <div
                          style={{
                            fontSize: 30,
                            fontWeight: "700",
                          }}
                        >
                         Es un placer que estés aquí con nosotros.
                        </div>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: "700",
                          }}
                        >
                          ¡Es hora de convertirte en un Socio Basi! Por favor completa tus datos:
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                            fontSize: 18,
                            fontWeight: "700",
                            color: "#003D28 ",
                          }}
                        >
                          Paso 1:
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <Field
                            disabled={false}
                            component={TextField}
                            type="text"
                            id="phone"
                            name="phone"
                            inputProps={{ maxLength: 8 }}
                            values={values.phone}
                            onChange={handleChange}
                            style={{
                              borderWidth: 1,
                              color: "green",
                              borderColor: "black",
                              width: "100%",
                              marginBottom: 10,
                            }}
                            label="Tu Número de teléfono"
                            // variant="filled"
                          />
                          <Field
                            component={TextField}
                            type="email"
                            id="email"
                            name="email"
                            disabled={false}
                            values={values.email}
                            onChange={handleChange}
                            style={{
                              color: "green",
                              borderColor: "black",
                              width: "100%",
                              marginBottom: 10,
                            }}
                            label="Correo Electrónico"
                            // variant="filled"
                          />
                          <Field
                            disabled={false}
                            component={TextField}
                            type="password"
                            id="password"
                            name="password"
                            // defaultValue="password"
                            values={values.password}
                            onChange={handleChange}
                            style={{
                              color: "green",
                              borderColor: "black",
                              width: "100%",
                              marginBottom: 10,
                            }}
                            label="Contraseña"
                            // variant="filled"
                          />
                        </div>
                        <div style={{ color: "red" }}>{error}</div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            type="submit"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#003D28 ",
                              width: 150,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 10,
                              padding: 10,
                              color: "white",
                              marginTop: 20,
                            }}
                          >
                            Continuar
                          </button>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{ fontWeight: "600" }}
                          component="h1"
                          variant="h5"
                        >
                          ¿Ya tienes una cuenta?{" "}
                        </Typography>

                        <button
                          onClick={() => navigation("/")}
                          type="submit"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#003D28 ",
                            width: 150,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 10,
                            padding: 9,
                            color: "white",
                            marginTop: 10,
                            marginBottom: 20,
                          }}
                        >
                          Ingresar
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default VendorSignUp;

const Styles = {
  SignInButton: {
    backgroundColor: "#003D28 ",
    borderRadius: 7,
    width: "50%",
    fontSize: 12,
  },
  OrStyling: {
    backgroundColor: Colors.darkBlue,
    borderRadius: 20,
    color: Colors.white,
    padding: 5,
    textAlign: "center",
  },
  Divider: {
    width: "50%",
    justifyContent: "center",
    alignSelf: "center",
  },
  Box: {
    padding: 30,
    borderRadius: 20,
    backgroundColor: Colors.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  SignUpButton: {
    backgroundColor: Colors.darkBlue,
  },
  SignUpText: {
    fontSize: 15,
    width: "100%",
  },
};
