import { Box, Grid } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";
import Colors from "../../../components/Colors";
import SignIn from "./SignIn";

const Login = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "90vh" }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "#003D28 ",
          height: 60,
          position: "fixed",
          top: 0,
        }}
      >
        <div>
          <img
            style={{ width: 220, height: 100, margin: -20 }}
            src={require("../../../assets/logo1.png")}
          />
        </div>
      </div>
      <Grid item lg={3} sm={6} md={4} sx={12}>
        <SignIn />
      </Grid>
    </Grid>
  );
};
export default Login;
