import { Button, Grid, IconButton, Input } from "@mui/material";
import React, { useRef, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TextField } from "formik-material-ui";
// import TextField from "@mui/material/TextField";

import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BoltIcon from "@mui/icons-material/Bolt";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import "react-phone-number-input/style.css";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Checkbox } from "@mui/material";
import { CreditCard } from "@material-ui/icons";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Avatar from "@mui/material/Avatar";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useJsApiLoader, GoogleMap, MarkerF } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import DatePickerComponent from "../../components/DatePickerComponent";
import "./vendor.css";
import { Event } from "@mui/icons-material";
const addressScheme = Yup.object().shape({
  firstName: Yup.string().required("Your first name is required"),
  documentNo: Yup.number().required("Your document number is required").max(9),
  lastName: Yup.string().required("Your last name is required"),
  documentNo: Yup.string()
    .required("Your document number is required")
    .max(9, "max number should be 9")
    .min(9, "min number should be 9"),
  // birthDate: Yup.string().required("Your birthDate is required"),
  //   guy: Yup.string().required("Your guy is required"),
});
function VendorSignUp() {
  let formData = new FormData();
  const navigation = useNavigate();
  const location = useLocation();
  console.log(location, "location");
  const params = location.state.data;
  const newParams = location.state.params;
  const [form, setForm] = useState();
  console.log(form?.address, "myform");
  const [dateValue, setDateValue] = React.useState(null);
  const [storeImage, setStoreImage] = useState(null);
  const [img, setImg] = useState(null);
  const [age, setAge] = React.useState("");
  const [error, setError] = React.useState("");
  const [disabled, setDisabled] = useState(true);
  const handleGuy = (e) => {
    setAge(e.target.value);
  };
  console.log(dateValue, "dateValue");
  const handleSubmit = (values) => {
    console.log(values, "age");
    var currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";
    const myAge = ~~((Date.now(currentDate) - dateValue) / 31557600000);
    console.log(currentDate, "myAge");
    console.log(myAge, "myAge");
    if (myAge <= 18) {
      setError("Invalid age");
    } else {
      setError("");
      setDateValue(dateValue);
      setDisabled(false);
      console.log(values, "valuesvaluesvalues");
      values.image = img;
      console.log(values.image);

      setForm({ address: { ...values } });
      navigation("/VendorAddress", {
        state: {
          data: { ...values, guy: age, birthDate: dateValue },
        },
      });
    }
  };

  const handleChangeImage = (event) => {
    console.log(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      formData.append("image", event.target.files[0]);
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        setImg(event.target.files[0]);
        setStoreImage(e.target.result);
      };
    }
  };
  return (
    <Grid
      container
      md={12}
      xs={12}
      style={{ backgroundColor: "#F7F7F7", minHeight: "100vh" }}
    >
      <Grid
        item
        style={{
          backgroundColor: "#003D28 ",
          height: 60,
          display: "flex",
          alignItems: "center",
          position: "fixed",
          width: "100%",
        }}
        xs={12}
        md={12}
      >
        <img
          style={{ width: 220, height: 100, margin: -20 }}
          src={require("../../assets/logo1.png")}
        />
      </Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 100,
        }}
        container
        xs={12}
        md={12}
        lg={12}
      >
        <Grid md={7} xs={10} item>
          <div style={{}}>
            <Formik
              initialValues={{
                firstName: newParams ? newParams?.firstName : "",
                lastName: newParams ? newParams?.lastName : "",
                birthDate: newParams ? newParams?.birthDate : "",
                documentNo: newParams ? newParams?.documentNo : "",
                ...params,
              }}
              validationSchema={addressScheme}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({
                values,

                handleChange,
                handleBlur,
                isSubmitting,
              }) => (
                <div>
                  <Form encType="multipart/form-data">
                    <div>
                      {/* Vendor Email and Number */}
                      <div style={{ padding: 5 }}>
                        <div
                          style={{
                            fontSize: 40,
                            fontWeight: "700",
                          }}
                        >
                          Es un placer que estés aquí con nosotros.
                        </div>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: "700",
                          }}
                        >
                          ¡Es hora de convertirte en un Socio Basi! Por favor
                          completa tus datos:
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                            fontSize: 18,
                            fontWeight: "700",
                            color: "#003D28 ",
                          }}
                        >
                          Paso 2:
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: 23,
                            }}
                          >
                            {storeImage && (
                              <Avatar
                                src={storeImage}
                                style={{
                                  display: "flex",
                                  width: 100,
                                  height: 100,

                                  alignSelf: "center",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifySelf: "center",
                                }}
                              />
                            )}

                            <input
                              type="file"
                              style={{ display: "none" }}
                              accept="image/*"
                              onChange={handleChangeImage}
                              id="contained-button-file"
                            />
                            <label
                              style={{ alignSelf: "center" }}
                              htmlFor="contained-button-file"
                            >
                              <IconButton
                                style={{ backgroundColor: "lightgray" }}
                                component="span"
                              >
                                <AddPhotoAlternateIcon />
                              </IconButton>
                            </label>
                            <div style={{ fontWeight: "600", marginLeft: 10 }}>
                              {img == null
                                ? "Selecciona la imagen de la tienda"
                                : ""}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: 23,
                              height: 57,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "70%",
                                height: 57,
                              }}
                            >
                              <Field
                                component={TextField}
                                type="text"
                                id="firstName"
                                name="firstName"
                                values={values.firstName}
                                label="Primer Nombre"
                                // defaultValue="values"
                                onChange={handleChange}
                                style={{
                                  borderWidth: 1,
                                  color: "green",
                                  borderColor: "black",
                                  width: "100%",
                                  marginBottom: 23,
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "70%",
                                height: 57,
                              }}
                            >
                              <Field
                                component={TextField}
                                type="text"
                                id="lastName"
                                name="lastName"
                                label="Apellidos"
                                values={values.lastName}
                                // defaultValue="values"
                                onChange={handleChange}
                                style={{
                                  borderWidth: 1,
                                  color: "green",
                                  borderColor: "black",
                                  width: "100%",
                                }}
                              />
                            </div>
                            <div>
                              <DatePickerComponent
                                value={dateValue}
                                setValue={setDateValue}
                                setError={setError}
                                error={error}
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "68%",
                                height: 70,
                              }}
                            >
                              <Field
                                component={TextField}
                                id="documentNo"
                                name="documentNo"
                                values={values.documentNo}
                                label="Ingresa el número de documento"
                                type="number"
                                appearance="none"
                                // defaultValue="values"
                                onChange={handleChange}
                                style={{
                                  borderWidth: 1,
                                  color: "green",
                                  borderColor: "black",
                                  width: "100%",
                                  marginBottom: 10,
                                }}
                              />
                            </div>
                            <FormControl
                              sx={{ width: "32%", height: 70, marginLeft: 0.5 }}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Selecciona el tipo de documento
                              </InputLabel>

                              <Select
                                id="demo-simple-select-label"
                                value={age}
                                onChange={handleGuy}
                                label="Selecciona el tipo de documento"
                              >
                                <MenuItem value="ID">Número de Cédula</MenuItem>
                                <MenuItem value="Immigration Card">
                                  Cédula de Residencia
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            disabled={img == null ? true : false}
                            type="submit"
                            // disabled={disabled}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#003D28 ",
                              width: 150,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 10,
                              padding: 10,
                              color: "white",
                              marginTop: 20,
                            }}
                          >
                            Continuar
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default VendorSignUp;
